import {
    ApproveBalancer,
    ApproveBalancerExtension,
    FrontendApproveBalancer
} from '@/includes/logic/ApproveBalancer/types'
import store from '@/store/store'
import { IterableItemFactory } from '@/components/Triggers/iterable-item-factory'
import { warningNotification } from '@/includes/services/NotificationService'
import i18n from '@/i18n/i18n'

import { cloneDeep } from 'lodash'
import moment from 'moment'
import { Route } from 'vue-router'

type ApproveBalancerState = {
    frApproveBalancer: FrontendApproveBalancer | null
    backup: FrontendApproveBalancer | null
}

// data
export const balancerState: ApproveBalancerState = {
    frApproveBalancer: null,
    backup: null
}

const extensionTemplate: ApproveBalancerExtension = {
    begin: moment().format('HH:mm:ss'),
    end: moment().add(1, 'h').format('HH:mm:ss'),
    percent: 0
}

const iterableItemFactory = new IterableItemFactory()

//convert
export const getRawApproveBalancer = () => {
    if (store.state.channelsState.activeChannel) {
        return cloneDeep(store.state.channelsState.activeChannel.config.approve_balancer)
    }

    return null
}

export function prepareFrontendBalancer(): FrontendApproveBalancer | null {
    const raw = getRawApproveBalancer()
    let frontend: FrontendApproveBalancer | null = null;

    if (raw) {
        frontend = {
            active: raw.active,
            limit: raw.limit,
            period: raw.period,
            extensions: [],
        }

        if (raw.extensions.length) {
            frontend.extensions = raw.extensions.map(e => iterableItemFactory.create(e))
        }

        return frontend
    }

    return null
}

export function prepareRawBalancer(): ApproveBalancer | null {
    const data = cloneDeep(balancerState.frApproveBalancer)
    let raw: ApproveBalancer | null = null

    if (data) {
        raw = {
            active: data.active,
            limit: data.limit,
            period: data.period,
            extensions: [],
        }

        if (data.extensions.length) {
            raw.extensions = data.extensions.map(e => e.value)
        }

        return raw
    }

    return null
}

export function toggleBalancerActive() {
    balancerState.frApproveBalancer!.active = !balancerState.frApproveBalancer!.active
}

export function addExtension() {
    balancerState.frApproveBalancer!.extensions.push(iterableItemFactory.create(cloneDeep(extensionTemplate)))
}

export function removeExtension(guid: string) {
    balancerState.frApproveBalancer!.extensions = balancerState.frApproveBalancer!.extensions.filter(e => e.guid !== guid)

    warnIfRemoveExistedExtension(guid)
}

export function warnIfRemoveExistedExtension(guid: string) {
    if (balancerState.backup && balancerState.backup.extensions.find(e => e.guid === guid)) {
        saveSettingsWarn()
    }
}

export function warnIfActiveChange() {
    if (balancerState.backup?.active !== balancerState.frApproveBalancer?.active) {
        saveSettingsWarn()
    }
}

export function init() {
    balancerState.frApproveBalancer = prepareFrontendBalancer();
    balancerState.backup = cloneDeep(balancerState.frApproveBalancer);
}

export function save(route: Route) {
    const raw = prepareRawBalancer();

    if (raw) {
        store.commit('pi/EXEC', {
            'fn': () => {
                store.state.channelsState.activeChannel!.config!.approve_balancer = raw
            },
        })

        store.dispatch('save_active_channel_config', route)
            .then(() => init())
    }
}

export const saveSettingsWarn = ():void =>{
    warningNotification(i18n.t('save_config_notification').toString())
}